<script>

export default {
  name: 'MobileHeader',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },

    dropdownLanguages: {
      type: Object,
      required: true
    },

    currentLanguageCode: {
      type: String,
      required: true
    },

    isScrolled: {
      type: Boolean,
      required: true
    },

    totalUsers: {
      type: Number,
      required: true
    },


  }, 

  data() {
    return {
      firstToolbarHeight: 0,
      secondToolbarHeight: 0,
      menuLineHeight: 42,
    }
  },

  async created() {

  },

  async mounted() {
    this.calculateHeights();
    window.addEventListener('resize', this.calculateHeights);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.calculateHeights);
  },
  
  methods: {
    calculateHeights() {
      const firstToolbar = this.$refs.firstToolbar;
      if (firstToolbar?.$el) {
        const toolbarElement = firstToolbar.$el; // Реальный DOM-элемент
        console.log('Toolbar height:', toolbarElement.offsetHeight);
        this.firstToolbarHeight = toolbarElement.offsetHeight;
        this.secondToolbarHeight = window.innerHeight - this.firstToolbarHeight;
      } else {
        console.error('Unable to find the DOM element for the toolbar.');
      }
    },

    async getNewInterface(languageLabel) {
      this.$emit('new-interface', languageLabel);
    },

    scrollChapter(chapter) {
      this.$emit('scroll-to-chapter', chapter);
    },

    getLanguageFlagPath(code) {
      try {
        return "/images/langs/" + code + ".png";
      } catch {
        return "/images/langs/en.png";
      }
    },

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    },

    openExternalLink(url) {
      if (url) {
        window.open(url, '_blank'); // Открывает ссылку в новой вкладке
      }
    },

    goHome() {
      this.$emit('scroll-to-top');
    }

  },    
  
  computed: {
    screenHeight() {
      return window.innerHeight;
    }
  }

}

</script>

<template>
  <q-toolbar style="flex-direction: column">    
    <div class="flex justify-between" style="width: 100%">
      <div class="flex justify-start">
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_discord_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_discord_link)">          
          <img src="/images/social/40_white_discord.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_telegram_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_telegram_link)">          
          <img src="/images/social/40_white_telegram.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_x_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_x_link)">          
          <img src="/images/social/40_white_x.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_youtube_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_youtube_link)">          
          <img src="/images/social/40_white_youtube.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_facebook_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_facebook_link)">          
          <img src="/images/social/40_white_facebook.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_reddit_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_reddit_link)">          
          <img src="/images/social/40_white_reddit.png">
        </div>
      </div>
      <div class="flex justify-end">
        <q-btn-dropdown flat no-caps>
          <template v-slot:label>
            <div class="row items-center no-wrap">
              <img :src="getLanguageFlagPath(currentLanguageCode)">
              <div class="text-center">

              </div>
            </div>
          </template>

          <q-list class="bg-indigo-10" style="color: aliceblue">
            <q-item v-for="(lang) in dropdownLanguages" :key="lang.language_label" clickable v-close-popup
              @click="getNewInterface(lang.language_label)">
              <q-item-section avatar>
                <img :src="getLanguageFlagPath(lang.language_label)">
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ lang.language_native }}</q-item-label>
              </q-item-section>
            </q-item>

          </q-list>
        </q-btn-dropdown>
      </div>

    </div>

    
  
    <q-toolbar-title class="row flex justify-between" style="cursor: pointer; width: 100%; padding: 0px; margin: 0px">
      <div @click="goHome" class="flex justify-start" style="font-size: 32px; font-weight: bold; padding: 0px; margin: 0px">
        {{ currentInterface.header_websitename }}
      </div>
      <div class="flex justify-end" style="font-size: 36px; font-weight: bold;">
        <q-btn-dropdown flat no-caps>
          <template v-slot:label>
            <div class="row items-center no-wrap">
              <q-icon left name="menu" />
            </div>
          </template>

          <q-list class="mobile-menuitems">
            <q-item clickable v-close-popup @click="scrollChapter('description')">
              <span class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_description }}</span>
            </q-item>
            <q-item clickable v-close-popup @click="scrollChapter('roadmap')">
              <span class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_roadmap }}</span>
            </q-item>
            <q-item clickable v-close-popup @click="scrollChapter('token')">
              <span class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_token }}</span>
            </q-item>
            <q-item clickable v-close-popup @click="scrollChapter('toplist')">
              <span class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_toplist }}</span>
            </q-item>
            <q-item clickable v-close-popup @click="scrollChapter('about')">
              <span class="q-pr-lg" style="cursor: pointer;">{{ currentInterface.menuitem_about }}</span>
            </q-item>
          
          </q-list>
        </q-btn-dropdown>
      </div>
    </q-toolbar-title>


  </q-toolbar>

</template>

<style scoped>
.mobile-menubar {
  font-size: 9px;
}
.mobile-menuitems {  
  background-color: rgba(5 5, 32, 0.9);
  color: aliceblue;
  text-transform: uppercase;
}
</style>
