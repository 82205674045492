<script>

export default {
  name: 'MobileToken',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },
      
  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    }
  },    
  
  computed: {    
  }

}

</script>

<template>

  <div class="container flex" style="flex-direction: column;">

    <div class="row flex justify-center text-uppercase" style="font-size: 32px;">{{ currentInterface.title_token }}</div>
    <div class="row q-pt-sm q-pb-lg flex" style="object-fit: scale-down; max-width: 100vw;">
      <img src="/images/token_image.jpg" class="article-image">
    </div>

    <div class="row text-content">
      <p>{{ currentInterface.text_token_1 }}</p>
      <p>{{ currentInterface.text_token_2 }}</p>
      <p>{{ currentInterface.text_token_3 }}</p>
    </div>
    

  </div>

</template>

<style scoped>
.text-content {
  font-size: 18px;
  text-align: justify;  
}

.article-image {
  border-radius: 12px;
  object-fit: scale-down;
  max-width: 100%;
}
</style>
