<script>
export default {
  name: 'AppFooter',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },

    copyrightText: {
      type: String,
      required: true
    }

  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

  },
  
  computed: {  

  }

}

</script>

<template>


    <q-toolbar class="content-wrapper">                
      <q-toolbar-title>
        {{ currentInterface.header_websitename }}
      </q-toolbar-title>

      <div style="padding-right: 24px;">
        {{ copyrightText }}
        {{ currentInterface.companyname }}
      </div>
    </q-toolbar>

  
  
  
</template>

<style scoped>

.content-container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr; /* Централизуем контент */
}

.content-wrapper {
  grid-column: 2; /* Контент находится во втором столбце (центральном) */
  padding: 12px;
}

</style>
