<script>
import settings from '../settings.json';
export default {
  name: 'MobileToplist',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },
    boxerlistFights: {
      type: Object,
      required: true
    },
    boxerlistWins: {
      type: Object,
      required: true
    }
      
  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    },

    getAvatar(avatarName) {
      try {
        return settings.serverUrl+'/static/avatars/' + avatarName;
      } catch {
        return "";
      }
    },

    getBanner(bannerName) {
      try {
        return settings.serverUrl+'/static/banners/' + bannerName;
      } catch {
        return "";
      }
    },

    getArrayLength(array) {
      try {
        return array.length;
      } catch {
        return 0;
      }
    },

    getWinsQuantity(array) {
      try {
        return array.filter(item => item.result === "win").length;
      } catch {
        return 0;
      }
    },

    getManagerName(boxer) {
      try {
        let firstname = '';
        let lastname = '';
        if (boxer.mgr_firstname && boxer.mgr_firstname !== null) {
          firstname = boxer.mgr_firstname;
        }
        if (boxer.mgr_lastname && boxer.mgr_lastname !== null) {
          lastname = boxer.mgr_lastname;
        }
        if (lastname === '' && firstname === '') {
          return 'Unknown User';
        } else if (lastname === '') {
          return firstname;
        } else if (firstname === '') {
          return lastname;
        } 
        return firstname + ' ' + lastname;
      } catch {
        return 'Unknown User';
      }
    }
  },    
  
  computed: {    
  }

}

</script>

<template>

  <div class="container flex" style="flex-direction: column;">
    <div class="row flex justify-center text-uppercase" style="font-size: 32px;">{{ currentInterface.title_toplist }}</div>
    <div class="row text-content q-pb-md">
      <p>{{ currentInterface.subtitle_toplist }}</p>
    </div>    

    <div class="flex" style="flex-direction: column">

      

        <div class="row flex justify-center q-pb-md" style="font-size: 18px; text-transform: uppercase;">
          <div style="display: flex; background-color: red; border-radius: 14px; height: 28px; color: aliceblue; padding-left: 12px; padding-right: 12px; align-items: center;">
            <b>{{ currentInterface.subtitle_toplist_1 }}</b>
          </div>          
        </div>

        <div class="container flex justify-center">
          
          <div class="row flex justify-center q-mb-md boxer-card" v-for="(boxer, index) in boxerlistFights" :key="index"
            :style="{
                      backgroundImage:`linear-gradient(rgba(47, 79, 79, 0.8), rgba(47, 79, 79, 0.8)), url('${getBanner(boxer.sponsor_banner)}')`, 
                      backgroundSize: 'contain', 
                      backgroundPosition: 'center' 
                    }
            ">
              <div class="col-2" style="flex-direction: column;">
                <div class="row flex justify-center">
                  <q-avatar rounded style="width: 100%; height: auto">
                    <img :src="getAvatar(boxer.avatar)">
                  </q-avatar>
                </div>                
                <div class="row flex justify-center q-pt-xs" style="font-size: 14px; line-height: 1.2;">
                  {{ boxer.level + ' ' + currentInterface.level }}
                </div>
              </div>
              <div class="col-8 q-pl-sm">
                <div class="row" style="font-size: 16px; line-height: 1.1; font-weight: bold;">
                  {{ boxer.nickname }}
                </div>
                <div class="row" style="font-size: 24px; line-height: 1.1;">
                  {{ boxer.first_name + ' ' + boxer.last_name}} 
                </div>
                <hr class="light-separator">
                <div class="row" style="font-size: 14px; line-height: 1.2;">
                  {{ currentInterface.manager + ' ' + getManagerName(boxer) }}
                </div>
                <div class="row" style="font-size: 14px; line-height: 1.2;">
                  <b v-if="boxer.sponsor_name !== ''">{{ currentInterface.sponsor + ' ' + boxer.sponsor_name }}</b>
                  <b v-else>{{ currentInterface.no_sponsor }}</b>
                </div>
              </div>

              <div class="col-2 flex justify-center" style="align-items: center;">
                <div>
                  <div class="row justify-center" style="font-size: 12px; text-transform: uppercase;">{{ currentInterface.fights }}</div>
                  <div class="row justify-center" style="font-size: 32px; font-weight: bold">{{ textNumber(getArrayLength(boxer.history)) }}</div>
                </div>
              </div>
          

          </div>
        </div>
      

        <div class="row flex justify-center q-pt-xl q-pb-md" style="font-size: 18px; text-transform: uppercase;">
          <div style="display: flex; background-color: blue; border-radius: 14px; height: 28px; color: aliceblue; padding-left: 12px; padding-right: 12px; align-items: center;">
            <b>{{ currentInterface.subtitle_toplist_2 }}</b>
          </div>          
        </div>

        <div class="container flex justify-center">
          
          <div class="row flex justify-center q-mb-md boxer-card" v-for="(boxer, index) in boxerlistWins" :key="index"
            :style="{
            backgroundImage:`linear-gradient(rgba(47, 79, 79, 0.8), rgba(47, 79, 79, 0.8)), url('${getBanner(boxer.sponsor_banner)}')`, 
            backgroundSize: 'contain', 
            backgroundPosition: 'center' 
          }
          ">
            <div class="col-2" style="flex-direction: column;">
              <div class="row flex justify-center">
                <q-avatar rounded style="width: 100%; height: auto">
                  <img :src="getAvatar(boxer.avatar)">
                </q-avatar>
              </div>                
              <div class="row flex justify-center q-pt-xs" style="font-size: 14px; line-height: 1.2;">
                {{ boxer.level + ' ' + currentInterface.level }}
              </div>
            </div>
            <div class="col-8 q-pl-sm">
              <div class="row" style="font-size: 16px; line-height: 1.1; font-weight: bold;">
                {{ boxer.nickname }}
              </div>
              <div class="row" style="font-size: 24px; line-height: 1.1;">
                {{ boxer.first_name + ' ' + boxer.last_name}} 
              </div>
              <hr class="light-separator">
              <div class="row" style="font-size: 14px; line-height: 1.2;">
                {{ currentInterface.manager + ' ' + getManagerName(boxer) }}
              </div>
              <div class="row" style="font-size: 14px; line-height: 1.2;">
                <b v-if="boxer.sponsor_name !== ''">{{ currentInterface.sponsor + ' ' + boxer.sponsor_name }}</b>
                <b v-else>{{ currentInterface.no_sponsor }}</b>
              </div>
            </div>

            <div class="col-2 flex justify-center" style="align-items: center;">
              <div>
                <div class="row justify-center" style="font-size: 12px; text-transform: uppercase;">{{ currentInterface.wins }}</div>
                <div class="row justify-center" style="font-size: 32px; font-weight: bold">{{ textNumber(getWinsQuantity(boxer.history)) }}</div>
              </div>
            </div>
          </div>
        </div>
      
    </div>

  </div>
  
</template>

<style scoped>
.text-content {
  font-size: 18px;
  text-align: justify;
}
.boxer-card {
  color: aliceblue;
  width: 96%;
  background-color: rgba(47, 79, 79, 0.9);
  border-radius: 12px;
  padding: 8px;
  text-shadow: 
    1px 1px 0 rgba(0, 0, 0, 0.2),
   -1px -1px 0 rgba(0, 0, 0, 0.2),
    1px -1px 0 rgba(0, 0, 0, 0.2),
   -1px 1px 0 rgba(0, 0, 0, 0.2); /* Тени в разные стороны */
}
.light-separator {
  border: none; /* Убираем стандартную границу */
  border-top: 1px dashed #ccc; /* Тонкая, прерывистая линия */
  margin: 8px 0; /* Отступы сверху и снизу */
}
</style>
