<script>

export default {
  name: 'MobileDescription',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },
      
  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    }
  },    
  
  computed: {    
  }

}

</script>

<template>

  <div class="container flex" style="flex-direction: column;">
    <div class="row flex justify-center text-uppercase" style="font-size: 32px;">{{ currentInterface.title_descriptition }}</div>
    <div class="row text-content">
      <p>{{ currentInterface.text_description_1 }}</p>
      
    </div>

    <div class="row flex justify-center" style="object-fit:scale-down;">
      <img src="/images/phone.png" style="display: flexbox; object-fit: scale-down; max-width: 90%;">
    </div>

    <div class="row text-content q-pt-xl">      
      <p>{{ currentInterface.text_description_2 }}</p>
      <p>{{ currentInterface.text_description_3 }}</p>
      <p>{{ currentInterface.text_description_4 }}</p>
      <p>{{ currentInterface.text_description_5 }}</p>
      <p>{{ currentInterface.text_description_6 }}</p>      
    </div>

    <div class="row text-content q-pt-md">
      <p>{{ currentInterface.text_description_7 }}</p>
    </div>

  </div>

</template>

<style scoped>
.text-content {
  font-size: 18px;
  text-align: justify;
}
</style>
