<script>

export default {
  name: 'AppAbout',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },
      
  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    }
  },    
  
  computed: {    
  }

}

</script>

<template>

  <div class="container flex" style="flex-direction: column;">
    <div class="row flex justify-center text-uppercase" style="font-size: 48px;">{{ currentInterface.title_about }}</div>
    <div class="row text-content">
      <p>{{ currentInterface.text_about_1 }}</p>      
    </div>
  </div>

</template>

<style scoped>
.text-content {
  font-size: 18px;
  text-align: justify;
}
</style>
