<script>
import axios from 'axios';
import settings from './settings.json';
import defaultInterface from './interface.json';
import AppHeader from './components/Header.vue';
import AppDescription from './components/Description.vue';
import AppRoadmap from './components/Roadmap.vue';
import AppToken from './components/Token.vue';
import AppToplist from './components/Toplist.vue';
import AppAbout from './components/About.vue';
import AppFooter from './components/Footer.vue';
import MobileHeader from './components/mHeader.vue';
import MobileDescription from './components/mDescription.vue';
import MobileRoadmap from './components/mRoadmap.vue';
import MobileToken from './components/mToken.vue';
import MobileToplist from './components/mToplist.vue';
import MobileAbout from './components/mAbout.vue';
import MobileFooter from './components/mFooter.vue';

export default {
  name: 'LayoutDefault',

  components: {
    AppHeader,
    AppDescription,
    AppRoadmap,
    AppToken,
    AppToplist,
    AppAbout,
    AppFooter,
    MobileHeader,
    MobileDescription,
    MobileRoadmap,
    MobileToken,
    MobileToplist,
    MobileAbout,
    MobileFooter

  },

  data() {
    return {
      currentLanguageCode: 'en',
      currentInterface: {},
      interfaceIsLoaded: false,
      interfacesAvailable: [ { "language_label": "en", "language_native": "English", "language_english": "English" } ],
      totalUsers: 0,
      boxerlistFights: [],
      boxerlistWins: [],
      isScrolled: false,
      isMobile: false
    }
  },

  async created() {
    this.getDeviceType();
    this.currentInterface = defaultInterface;
    await this.getInterface(this.currentLanguageCode);
    await this.getBoxerlistFights('10');
    await this.getBoxerlistWins('10');
  },

  
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.getDeviceType);
  },
  
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.getDeviceType);
  },

  methods: {
    getDeviceType() {
      this.isMobile = window.innerWidth < 1200;
    },

    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },

    async getInterface(languageLabel) {      
      try {
        const response = await axios.post(`${settings.serverUrl}/api/get_website_interface`, {
          apiData: languageLabel
        });
        if (response.data.status) {
          console.log('Interface loaded successfully');
          this.currentInterface = { ...this.currentInterface, ...response.data.interface };
          this.interfacesAvailable = response.data.interfaces_available;
          this.currentLanguageCode = languageLabel;
          this.totalUsers = response.data.total_users;
        }
        this.interfaceIsLoaded = true;
      } catch (error) {
        console.error('Error sending app connect data:', error);
      }
    },

    async getBoxerlistFights(n) {
      try {
        const response = await axios.post(`${settings.serverUrl}/api/get_top_boxers_fights`, {
          apiData: n
        });
        if (response.data.status) {
          console.log('FUNCTION getBoxerlistFights: ', response.data);          
          this.boxerlistFights = response.data.boxerlist;
        }
      } catch (error) {
        console.error('FUNCTION getBoxerlistFights: ', error);
      }
    },

    async getBoxerlistWins(n) {
      try {
        const response = await axios.post(`${settings.serverUrl}/api/get_top_boxers_wins`, {
          apiData: n
        });
        if (response.data.status) {
          console.log('FUNCTION getBoxerlistWins: ', response.data);          
          this.boxerlistWins = response.data.boxerlist;
        }
      } catch (error) {
        console.error('FUNCTION getBoxerlistWins: ', error);
      }
    },

    getLanguageFlagPath(code) {
      try {
        return "../images/langs/" + code + ".png";
      } catch {
        return "../images/langs/en.png";
      }
    },

    scrollToChapter(chapter) {
      /*const articleElement = document.getElementById(chapter);
      if (articleElement) {
        articleElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }*/
      const articleElement = document.getElementById(chapter);
      if (articleElement) {
        const headerOffset = document.querySelector('header')?.offsetHeight || 0; // Высота фиксированного хедера
        const elementPosition = articleElement.getBoundingClientRect().top + window.scrollY; // Позиция элемента относительно документа
        const offsetPosition = elementPosition - headerOffset; // Учесть хэдер

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Плавная прокрутка
      });
    }
  },
  
  computed: {
    dropdownLanguages() {
      return this.interfacesAvailable.filter(
        (language) => language.language_label !== this.currentLanguageCode
      );
    },
    copyrightText() {
      const launchYear = 2024;
      const currentYear = new Date().getFullYear();
      return launchYear === currentYear 
        ? `${currentYear} © ` 
        : `${launchYear}–${currentYear} © `;
    }
  }

}

</script>

<template>
  <q-layout v-if="!isMobile" view="lHh Lpr lff">
    
    <q-header :class="{'bg-indigo-10': isScrolled}" style="background-image: url('/images/header_background.jpg'); background-size: cover; background-position: center;">
      <AppHeader
        @new-interface="getInterface"
        @scroll-to-chapter="scrollToChapter"
        @scroll-to-top="scrollToTop"
        :currentInterface = "currentInterface"
        :dropdownLanguages = "dropdownLanguages"
        :currentLanguageCode = "currentLanguageCode"
        :isScrolled = "isScrolled"
        :totalUsers = "totalUsers"        
      />
    </q-header>
    
    <q-page-container class="content-container bg-red-2">      
      <div class="content-wrapper" style="flex-direction: column;">
        <div class="q-pt-xl"></div>
        <div class="q-pt-xl"></div>
        <AppDescription id="description" class="q-pb-xl"
          :currentInterface = "currentInterface" 
        />
        <AppRoadmap id="roadmap" class="q-pb-xl"
          :currentInterface = "currentInterface"
        />
        <AppToken id="token" class="q-pb-xl"
          :currentInterface = "currentInterface"
        />
        <AppToplist id="toplist" class="q-pb-xl"
          :currentInterface = "currentInterface"
          :boxerlistFights = "boxerlistFights"
          :boxerlistWins = "boxerlistWins"
        />
        <AppAbout id="about" class="q-pb-xl"
          :currentInterface = "currentInterface"
        />
      </div>
      
    </q-page-container>

    <q-footer class="content-container bg-indigo-10">
      <AppFooter 
        :currentInterface = "currentInterface"
        :copyrightText = "copyrightText" 
      />
    </q-footer>
    
  </q-layout>

  <q-layout v-if="isMobile" view="lH Lpr lff" style="flex-direction: column">
    <q-header style="background-image: url('/images/header_background.jpg'); background-size: cover; background-position: center;">
      <MobileHeader
        @new-interface="getInterface"
        @scroll-to-chapter="scrollToChapter"
        @scroll-to-top="scrollToTop"
        :currentInterface = "currentInterface"
        :dropdownLanguages = "dropdownLanguages"
        :currentLanguageCode = "currentLanguageCode"
        :isScrolled = "isScrolled"
        :totalUsers = "totalUsers"        
      />
    </q-header>

    <q-page-container class="mobile-container bg-red-2">      
      <div class="content-wrapper" style="flex-direction: column;">

        <MobileDescription id="description" class="q-pb-md"
          :currentInterface = "currentInterface" 
        />
        <MobileRoadmap id="roadmap" class="q-pb-md"
          :currentInterface = "currentInterface"
        />
        <MobileToken id="token" class="q-pb-md"
          :currentInterface = "currentInterface"
        />
        <MobileToplist id="toplist" class="q-pb-md"
          :currentInterface = "currentInterface"
          :boxerlistFights = "boxerlistFights"
          :boxerlistWins = "boxerlistWins"
        />
        <MobileAbout id="about" class="q-pb-md"
          :currentInterface = "currentInterface"
        />
      </div>
      
    </q-page-container>

    <q-footer class="content-container bg-indigo-10">
      <MobileFooter 
        :currentInterface = "currentInterface"
        :copyrightText = "copyrightText" 
      />
    </q-footer>

  </q-layout>

</template>

<style scoped>
.content-container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr; /* Централизуем контент */
}

.content-wrapper {
  grid-column: 2; /* Контент находится во втором столбце (центральном) */
  padding: 12px;
}

.mobile-container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr; /* Централизуем контент */
}

</style>
