<script>

export default {
  name: 'AppHeader',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },

    dropdownLanguages: {
      type: Object,
      required: true
    },

    currentLanguageCode: {
      type: String,
      required: true
    },

    isScrolled: {
      type: Boolean,
      required: true
    },

    totalUsers: {
      type: Number,
      required: true
    },


  }, 

  data() {
    return {
      firstToolbarHeight: 0,
      secondToolbarHeight: 0,
      menuLineHeight: 42,
    }
  },

  async created() {

  },

  async mounted() {
    this.calculateHeights();
    window.addEventListener('resize', this.calculateHeights);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.calculateHeights);
  },
  
  methods: {
    calculateHeights() {
      const firstToolbar = this.$refs.firstToolbar;
      if (firstToolbar?.$el) {
        const toolbarElement = firstToolbar.$el; // Реальный DOM-элемент
        console.log('Toolbar height:', toolbarElement.offsetHeight);
        this.firstToolbarHeight = toolbarElement.offsetHeight;
        this.secondToolbarHeight = window.innerHeight - this.firstToolbarHeight;
      } else {
        console.error('Unable to find the DOM element for the toolbar.');
      }
    },

    async getNewInterface(languageLabel) {
      this.$emit('new-interface', languageLabel);
    },

    scrollChapter(chapter) {
      this.$emit('scroll-to-chapter', chapter);
    },

    getLanguageFlagPath(code) {
      try {
        return "/images/langs/" + code + ".png";
      } catch {
        return "/images/langs/en.png";
      }
    },

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    },

    openExternalLink(url) {
      if (url) {
        window.open(url, '_blank'); // Открывает ссылку в новой вкладке
      }
    },

    goHome() {
      this.$emit('scroll-to-top');
    }

  },    
  
  computed: {
    screenHeight() {
      return window.innerHeight;
    }
  }

}

</script>

<template>

  <q-toolbar :class="{ 'content-container-header': isScrolled }" style="display: grid;" ref="firstToolbar">
    <div class="flex row justify-between" :class="{ 'content-wrapper-header': isScrolled }" style="align-items: center;">
      <div>        
        <q-toolbar-title @click="goHome" style="cursor: pointer">
          <div style="font-size: 48px; font-weight: bold;">
            {{ currentInterface.header_websitename }}
          </div>
        </q-toolbar-title>
      </div>

      <div class="row flex" style="align-items: center;">
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_discord_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_discord_link)">
          <img v-show="!isScrolled" src="/images/social/40_discord.png">
          <img v-show="isScrolled" src="/images/social/40_white_discord.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_telegram_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_telegram_link)">
          <img v-show="!isScrolled" src="/images/social/40_telegram.png">
          <img v-show="isScrolled" src="/images/social/40_white_telegram.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_x_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_x_link)">
          <img v-show="!isScrolled" src="/images/social/40_x.png">
          <img v-show="isScrolled" src="/images/social/40_white_x.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_youtube_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_youtube_link)">
          <img v-show="!isScrolled" src="/images/social/40_youtube.png">
          <img v-show="isScrolled" src="/images/social/40_white_youtube.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_facebook_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_facebook_link)">
          <img v-show="!isScrolled" src="/images/social/40_facebook.png">
          <img v-show="isScrolled" src="/images/social/40_white_facebook.png">
        </div>
        <div class="q-pr-sm q-pt-xs" v-if="currentInterface.social_reddit_link" style="cursor: pointer;" @click="openExternalLink(currentInterface.social_reddit_link)">
          <img v-show="!isScrolled" src="/images/social/40_reddit.png">
          <img v-show="isScrolled" src="/images/social/40_white_reddit.png">
        </div>
        
        <q-btn-dropdown flat no-caps>
          <template v-slot:label>
            <div class="row items-center no-wrap">
              <img :src="getLanguageFlagPath(currentLanguageCode)">
              <div class="text-center">

              </div>
            </div>
          </template>

          <q-list class="bg-blue">
            <q-item v-for="(lang) in dropdownLanguages" :key="lang.language_label" clickable v-close-popup
              @click="getNewInterface(lang.language_label)">
              <q-item-section avatar>
                <img :src="getLanguageFlagPath(lang.language_label)">
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ lang.language_native }}</q-item-label>
              </q-item-section>
            </q-item>

          </q-list>
        </q-btn-dropdown>
      </div>

    </div>
  </q-toolbar>

  <transition name="slide-fade">
    <q-toolbar v-if="!isScrolled" :style="{ height: (secondToolbarHeight - menuLineHeight) + 'px' }" class="content-container-header">
      <div class="content-wrapper-header" style="height: 100%;">
        <div class="row" style="height: 100%;">

          <div class="col-6 flex" style="height: 100%; align-items: center;">
            <div class="container" style="flex-direction: column;">
              <div class="row flex justify-start q-pb-md" style="font-size: 36px; line-height: 1.1;">
                <b>{{ currentInterface.header_text_join }}</b>
              </div>
              <div class="row flex justify-start" style="line-height: 1.1;">
                <img v-if="screenHeight > 700" src="/images/bot_qr.png">
                <img v-else src="/images/bot_qr_small.png">
              </div>
              <div class="row flex justify-start q-pt-md" style="font-size: 36px; line-height: 1.1;">
                <b>{{ currentInterface.header_text_create }}</b>
              </div>
            </div>
          </div>

          <div class="col-6 flex justify-end" style="height: 100%; align-items: center; font-family: Tahoma, sans-serif">

            <div class="container" style="flex-direction: column; display: flex; text-align: right;">
              <div class="row flex justify-end" style="font-size: 72px; line-height: 1.1; align-items: bottom">
                <b>{{ currentInterface.header_text_already }}</b>
              </div>
              <div class="row flex justify-end" style="font-size: 122px; line-height: 1.1;">
                <span><b>{{ textNumber(totalUsers) }}</b></span>
              </div>
              <div class="row flex justify-end" style="font-size: 72px; line-height: 1.1; align-items: start;">
                <b>{{ currentInterface.header_text_users }}</b>
              </div>
            </div>

          </div>

        </div>
        
      </div>
    </q-toolbar>
  </transition>

  <q-toolbar class="content-container-header" :style="{height: menuLineHeight + 'px'}">
    <div class="content-wrapper-header row flex justify-between text-uppercase menubar">
      <span @click="scrollChapter('description')" class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_description }}</span>
      <span @click="scrollChapter('roadmap')" class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_roadmap }}</span>
      <span @click="scrollChapter('token')" class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_token }}</span>
      <span @click="scrollChapter('toplist')" class="q-pr-xl" style="cursor: pointer;">{{ currentInterface.menuitem_toplist }}</span>
      <span @click="scrollChapter('about')" class="q-pr-lg" style="cursor: pointer;">{{ currentInterface.menuitem_about }}</span>
    </div>
  </q-toolbar>

</template>

<style scoped>
.content-container-header {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr; /* Централизуем контент */
}

.content-wrapper-header {
  grid-column: 2; /* Контент находится во втором столбце (центральном) */  
  padding-left: 12px;
  padding-right: 12px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: max-height 0.1s ease, opacity 0.1s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 500px;
  opacity: 1;
}

.menubar {
  font-size: 20px;
}
</style>
