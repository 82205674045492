<script>

export default {
  name: 'AppRoadmap',

  components: {

  },

  props: {
    currentInterface: {
      type: Object,
      required: true
    },
      
  }, 

  data() {
    return {
      
    }
  },

  async created() {

  },

  async mounted() {
    
  },

  beforeUnmount() {
    
  },
  
  methods: {

    textNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return "n/a";
      }
      return value.toLocaleString('en-US');
    }
  },    
  
  computed: {    
  }

}

</script>

<template>

  <div class="container flex" style="flex-direction: column;">
    <div class="row flex justify-center text-uppercase" style="font-size: 48px;">{{ currentInterface.title_roadmap }}</div>

    <div class="row text-content">
      <p>{{ currentInterface.text_roadmap_subtitle }}</p>      
    </div>

    <div class="row">
      <div class="col-4 flex justify-center">
        <div class="roadmap-card-completed">
          {{ currentInterface.text_roadmap_1_title }} &#10004;
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_2_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_3_title }}
        </div>
      </div>      
    </div>

    <div class="row q-pt-md">
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_4_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_5_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_6_title }}
        </div>
      </div>      
    </div>

    <div class="row q-pt-md">
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_7_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_8_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_9_title }}
        </div>
      </div>      
    </div>

    <div class="row q-pt-md">
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_10_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_11_title }}
        </div>
      </div>
      <div class="col-4 flex justify-center">
        <div class="roadmap-card">
          {{ currentInterface.text_roadmap_12_title }}
        </div>
      </div>      
    </div>

  </div>

</template>

<style scoped>
.text-content {
  font-size: 18px;
  text-align: justify;  
}
.roadmap-card {
  display: flex;
  border-radius: 24px;
  background-color: rgba(32, 96, 32, 0.7);
  width: 100%;
  height: 160px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  font-size: 24px;
  color: aliceblue;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.roadmap-card-completed {
  display: flex;  
  border-radius: 24px;
  background-color: rgba(0, 153, 0, 1);
  width: 100%;
  height: 160px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  font-size: 24px;
  color: aliceblue;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}


</style>
